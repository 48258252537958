import classNames from 'classnames'
import Link from 'next/link'
import { useState } from 'react'
import BSCard from 'react-bootstrap/Card'

import Card from '@/components/Card/Card'
import Icon from '@/components/Icon/Icon'
import { GenericCardType } from '@/types/card'

import styles from './CardHover.module.scss'

type Props = {
  card: GenericCardType
  left?: boolean
}

const CardHover = ({ card, left }: Props) => {
  const [isHovering, setIsHovering] = useState(false)
  const [mobileViewerIsOpen, setMobileViewerIsOpen] = useState(false)

  const { name, url } = card

  return (
    <>
      <span
        className='d-none d-sm-inline'
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <span>{url ? <Link href={url}>{name}</Link> : name}</span>
        {isHovering ? (
          <span className={classNames(styles.hover, { [styles.left]: left }, 'shadow-sm')}>
            <BSCard>
              <Card background card={card} />
            </BSCard>
          </span>
        ) : null}
      </span>
      <span className='d-inline d-sm-none'>
        <span className='fake-link' onClick={() => setMobileViewerIsOpen(true)}>
          {name}
        </span>
        {mobileViewerIsOpen && (
          <div className={styles.mobileViewer} onClick={() => setMobileViewerIsOpen(false)}>
            <div className='align-items-end d-flex flex-column'>
              <div className='position-relative'>
                <Icon className={classNames(styles.close, 'cursor-pointer text-danger')} icon='times' size='xl' />
              </div>
              <div onClick={(event) => event.stopPropagation()}>
                <Card background card={card} />
              </div>
            </div>
          </div>
        )}
      </span>
    </>
  )
}

export default CardHover
